import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "meta-example-image-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetaExampleImage = _resolveComponent("MetaExampleImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exampleImages, (img, index) => {
      return (_openBlock(), _createBlock(_component_MetaExampleImage, {
        ref_for: true,
        ref: "exampleImageRefs",
        key: img.id,
        imageData: img,
        delay: 500,
        onClick: ($event: any) => (_ctx.handleExampleClicked(img.prompt))
      }, null, 8, ["imageData", "onClick"]))
    }), 128))
  ]))
}