import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8225939c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "advanced-tags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PromptTag = _resolveComponent("PromptTag")!
  const _component_FadeInsertTransitionGroup = _resolveComponent("FadeInsertTransitionGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FadeInsertTransitionGroup, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagKeys, (key) => {
          return (_openBlock(), _createBlock(_component_PromptTag, {
            class: "advanced-setting-tag",
            size: "large",
            key: key,
            text: key,
            showCloseBtn: true,
            onDelete: _ctx.promptTagDelete
          }, null, 8, ["text", "onDelete"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}