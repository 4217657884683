import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e01a94c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "meta-main-input" }
const _hoisted_2 = { class: "mmi-input-area" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 1,
  class: "mmi-btns"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RotateRing = _resolveComponent("RotateRing")!
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_SlideI18nText = _resolveComponent("SlideI18nText")!
  const _component_MetaButton = _resolveComponent("MetaButton")!
  const _component_FadeTransition = _resolveComponent("FadeTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: "mmi-input-entity",
        ref: "input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.interText) = $event)),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.caretFocusState = false)),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.caretFocusState = true)),
        onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClickedInput && _ctx.handleClickedInput(...args))),
        onKeydown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleKeyDownInput && _ctx.handleKeyDownInput(...args))),
        placeholder: '  ' + _ctx.$t('mainInputPlaceholder'),
        onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        contenteditable: "true"
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.interText]
      ]),
      _createElementVNode("div", {
        class: "mmi-text-proxy",
        ref: "textProxy"
      }, _toDisplayString(_ctx.proxyText), 513),
      _createElementVNode("div", {
        class: _normalizeClass(["mmi-caret", {hidden: !_ctx.caretFocusState || _ctx.caretHiddenState,static:_ctx.caretStaticState}]),
        ref: "caret"
      }, null, 2)
    ]),
    _createVNode(_component_FadeTransition, null, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_RotateRing, {
              key: 0,
              class: "loading-ring"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_SvgIcon, {
                class: "mmi-clear-btn",
                name: "close-round",
                onClick: _ctx.handleClearBtnClicked
              }, null, 8, ["onClick"]),
              _createVNode(_component_MetaButton, {
                class: _normalizeClass(["mmi-generate-button", {
          disabled:_ctx.isEmpty
        }]),
                onClick: _ctx.handleGenerateBtnClicked
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SlideI18nText, { path: "generate" })
                ]),
                _: 1
              }, 8, ["class", "onClick"])
            ]))
      ]),
      _: 1
    })
  ]))
}