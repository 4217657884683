
import {Options, Vue} from 'vue-class-component';

@Options<FadeInsertTransitionGroup>({
  components: {},
  props: {}
})
export default class FadeInsertTransitionGroup extends Vue {

}
