import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f09c2d56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "meta-prod-image-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreationImage = _resolveComponent("CreationImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageColumns, (creations, cIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "meta-prod-image-columns",
        key: cIndex
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(creations, (img, index) => {
          return (_openBlock(), _createBlock(_component_CreationImage, {
            class: "mpi-image",
            imageData: img,
            showHDIcon: false,
            showPrompt: false,
            key: img.id,
            originalSizeRatio: true,
            onClick: ($event: any) => (_ctx.handleImageClicked(img))
          }, null, 8, ["imageData", "onClick"]))
        }), 128))
      ]))
    }), 128))
  ]))
}