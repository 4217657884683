
import {Options, Vue} from 'vue-class-component';
import MetaExampleImage from "@/components/MetaExampleImage.vue";
import {CommunityCreationData} from "@/types/CommunityCreationData";
@Options<MetaExampleImageGroup>({
  components: {
    MetaExampleImage,
  },
  props: {
    exampleImages:{
      required:true,
    }
  }
})
export default class MetaExampleImageGroup extends Vue {
  exampleImageRefs:Vue[] = [];
  exampleImages!:CommunityCreationData[];
  handleExampleClicked(desc:string){
    this.$emit('exemplify',desc);
  }
}
