
import {Options, Vue} from 'vue-class-component';
import {PropType} from "vue";
import PromptTag from "@/components/PromptTag.vue";
import FadeInsertTransitionGroup from "@/components/transitions/FadeInsertTransitionGroup.vue";
import _ from 'lodash';
import {colorLog} from "@/utils";


@Options<AdvancedTags>({
  components: {
    PromptTag,
    FadeInsertTransitionGroup
  },
  props: {
    modelValue:{
      required:true,
      type:Array as PropType<string[]>
    }
  },
  watch:{
    modelValue:{
      handler(val){
        this.tagKeys = val;
      },
      immediate:true
    },
    tagKeys:{
      handler(val){
        this.$emit('update:modelValue',val);
      },
    }
  }
})
export default class AdvancedTags extends Vue {
  modelValue!:string[];
  tagKeys:string[] = [];
  promptTagDelete(key:string){
    const copy = _.cloneDeep(this.tagKeys);
    _.pull(copy,key);
    this.tagKeys = copy;
  }
}
