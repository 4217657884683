
import {Options, Vue} from 'vue-class-component';
import {debounce, DomEventListenerManager,colorLog} from "@/utils";
import RotateRing from "@/components/RotateRing.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import SvgIcon from "@/components/SvgIcon.vue";

@Options<MetaMainInput>({
  components: {
    SvgIcon,
    FadeTransition,
    RotateRing
  },
  props: {
    text:{
      required:false,
      type:String,
      default:''
    },
    loading:{
      required:false,
      type:Boolean,
      default:false
    }
  },
  computed:{
    isEmpty(){
      return !this.interText.length;
    }
  },
  watch:{
    text:{
      handler(newVal){
        this.interText = newVal;
        this.updateInputCaretPosition(0);
      },
      immediate:true
    },
    interText(newVal){
      this.$emit('update:text',newVal);
    }
  }
})
export default class MetaMainInput extends Vue {
  loading!:boolean;

  interText = '';
  proxyText = '';
  isEmpty!:boolean;
  caretStaticState = false;
  caretHiddenState = false;
  caretFocusState = false;
  cancelStaticState = debounce(() => {
    this.caretStaticState = false;
  },100);

  domEventListenerManager = new DomEventListenerManager();
  created(){
    const selection = window.getSelection();
    this.domEventListenerManager.registerListener(document,'selectionchange',(event:Event) => {
      const input = this.$refs.input as HTMLInputElement;
      const collapse = input.selectionStart === input.selectionEnd;

      if(!collapse && selection!.getRangeAt(0).endContainer.firstChild === input){
        this.caretHiddenState = true;
      }else{
        if(this.caretHiddenState) this.updateInputCaretPosition(0);
        this.caretHiddenState = false;
      }
    });
    this.domEventListenerManager.registerListener(window,'resize',() => this.updateInputCaretPosition(0));
  }

  beforeUnmount(){
    this.domEventListenerManager.destroy();
  }

  handleInput(event:InputEvent){
    this.updateInputCaretPosition(0);
  }

  async updateInputCaretPosition(value:number | undefined,isAbsolute = false){
    this.setCaretStatic();
    const input = this.$refs.input as HTMLInputElement;
    if(!input) return;
    const end = isAbsolute ? value : ((input.selectionEnd as number) + (value as number));
    if(typeof end == 'number' && end < 0) {
      this.proxyText = '';
    }else{
      this.proxyText = input.value.slice(0,end);
    }

    await this.$nextTick();
    const caret = this.$refs.caret as HTMLElement;
    const textProxy = this.$refs.textProxy as HTMLElement;
    // colorLog('textProxy',textProxy.innerHTML);
    caret.style.setProperty('left',Math.min(Math.max(textProxy.clientWidth -  input.scrollLeft,0),input.clientWidth) + 'px');
  }

  handleClickedInput(event:MouseEvent){
    // console.log('mousedown',event)
    setTimeout(() => this.updateInputCaretPosition(0),0);
  }

  handleClearBtnClicked(){
    this.interText = '';
  }

  handleGenerateBtnClicked(){
    if(this.isEmpty) return;
    this.$emit('generate');
  }

  handleKeyDownInput(event:KeyboardEvent){
    // const input = this.$refs.input as HTMLInputElement;
    if(event.key.search('Arrow') > -1){
      switch (event.key){
        case 'ArrowLeft':
          this.updateInputCaretPosition(-1)
          break;
        case 'ArrowRight':
          this.updateInputCaretPosition(1)
          break;
        case 'ArrowUp':
          this.updateInputCaretPosition(0,true)
          break;
        case 'ArrowDown':
          this.updateInputCaretPosition(undefined,true)
          break;
      }
    }

    if(event.key === 'Enter'){
      this.handleGenerateBtnClicked();
    }

    // console.log("handleKeyDownInput",event.key)
  }

  setCaretStatic(){
    this.caretStaticState = true;
    this.cancelStaticState();
  }
}
