
import {Options, Vue} from 'vue-class-component';
import {
  AdvancedSettingData, backgroundDataType,
  settingDataType,
} from "@/types/AdvancedSettingData";
import WidthRelatedBox from "@/components/WidthRelatedBox.vue";
import {colorLog} from "@/utils";
import {PropType} from "vue";
@Options<MetaGeneratorSetting>({
  components: {WidthRelatedBox},
  emits:['update:modelValue'],
  props: {
    modelValue:{
      required:true,
      type:Array as PropType<string[]>,
    },
  },
  watch:{
    modelValue:{
      handler(this:MetaGeneratorSetting,val:string[]){
        const setRespectively = (key:string) => {
          const data = (this.settingData as unknown) as {[k:string]: { [p:string]:boolean | backgroundDataType }};
          Object.keys(data).forEach(k => {
            if(key in data[k]){
              const target = data[k][key];
              if(typeof target === 'object'){
                target.selected = true;
              }else{
                data[k][key] = true;
              }
            }else{
              Object.keys(data[k]).forEach(_key => {
                if(val.includes(_key) && _key !== key){
                  //do nothing
                }else{
                  const target = data[k][_key];
                  if(typeof target === 'object'){
                    target.selected = false;
                  }else{
                    data[k][_key] = false;
                  }
                }
              })
            }
          })
        };
        if(val.length === 0){
          //至少让这个方法执行一次
          setRespectively('');
        }
        val.forEach((k) => setRespectively(k));
      },
      immediate:true
    }
  },
  computed:{

  }
})
export default class MetaGeneratorSetting extends Vue {
  modelValue!:string[];
  settingData:AdvancedSettingData = {
    styles:{
      '3dRendering':false,
      "hand-painted":false,
      watercolour:false,
      illustration:false,
      sketch:false,
      photorealistic:false,
    },
    background:{
      black:{
        colorValue:'black',
        selected:false
      },
      white:{
        colorValue:'white',
        selected:false
      },
      red:{
        colorValue:'red',
        selected:false
      },
      blue:{
        colorValue:'blue',
        selected:false
      },
      gray:{
        colorValue:'gray',
        selected:false
      }
    },
    perspective:{
      front:false,
      rear:false,
      top:false,
      side:false,
      plain:false
    },
    distance:{
      normal:false,
      "close-ups":false,
      microscopic:false
    },
    other:{
      copySpace:false,
      longExposure:false,
      multipleExposures:false
    }
  };

  getProperties(){
    const gen = (obj:any) => {
      let path = '';
      for(const key of Object.getOwnPropertyNames(obj)){
        if(typeof obj[key] === 'boolean' && obj[key]){
          path = key;
          break;
        }

        if(typeof obj[key] === 'object' && obj[key].selected){
          path = key;
          break;
        }
      }

      if(path){
        return path;
      }
      return '';
    }

    let prompt:string[] = [];
    for(const className of Object.getOwnPropertyNames(this.settingData)){
      const para = gen(this.settingData[className as keyof AdvancedSettingData]);
      if(para){
        prompt.push(`${para}`);
      }
    }

    return prompt.filter(i=>i);
  }

  clearOperation(classRow:any,referSelected?:boolean){
    for(const key of Object.getOwnPropertyNames(classRow)){
      if(referSelected){
        classRow[key].selected = false;
      }else{
        classRow[key] = false;
      }
    }
  }

  chunkSettingDataProperty(prop:settingDataType){
    return Object.keys(prop).reduce((keys:any[],keyName:string,i) => {
      const index = i % 5;
      if(!keys[index]){
        keys[index] = [];
      }
      keys[index].push(keyName);
      return keys;
    },[])
  }
}
