import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-184b92a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "meta-example-image" }
const _hoisted_2 = {
  class: "mi-image-panel",
  ref: "imagePanelRef"
}
const _hoisted_3 = { class: "example-detail-panel" }
const _hoisted_4 = { class: "edp-top" }
const _hoisted_5 = { class: "example-origin-text" }
const _hoisted_6 = { class: "edp-bottom example-try" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetaImage = _resolveComponent("MetaImage")!
  const _component_SlideI18nText = _resolveComponent("SlideI18nText")!
  const _component_WidthRelatedBox = _resolveComponent("WidthRelatedBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WidthRelatedBox, { height: "100%" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "example-pre-desc-panel",
          ref: "preDescPanelRef"
        }, _toDisplayString(_ctx.imageData.prompt), 513),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MetaImage, {
            class: "mi-image",
            alt: _ctx.imageData.prompt,
            src: _ctx.imageData.url2048 || _ctx.imageData.url1024 || _ctx.imageData.urlSmall,
            onLoad: _ctx.onImageLoaded
          }, null, 8, ["alt", "src", "onLoad"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.imageData.prompt), 1)
            ]),
            _createElementVNode("p", _hoisted_6, [
              _createVNode(_component_SlideI18nText, { path: "clickToTry" })
            ])
          ])
        ], 512)
      ]),
      _: 1
    })
  ]))
}