
import {Options, Vue} from 'vue-class-component';
import MetaExampleImage from "@/components/MetaExampleImage.vue";
import MetaButton from "@/components/MetaButton.vue";
import anime from "animejs";
import {
  AbortReceiver,
  generateRelaxModeImages,
  getExampleImages,
  getGeneratedImages,
  getGenerateTask,
  getRelaxTask
} from "@/api";
import LoadingPanel from "@/components/LoadingPanel.vue";
import WidthRelatedBox from "@/components/WidthRelatedBox.vue";
import MetaMainInput from "@/components/MetaMainInput.vue";
import MetaGeneratorSetting from "@/components/MetaGeneratorSetting.vue";
import MetaExampleImageGroup from "@/components/MetaExampleImageGroup.vue";
import MetaProdImageGroup from "@/components/MetaProdImageGroup.vue";
import store from "@/store";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import {ElMessage} from "element-plus";
import {CreationsImagesData} from "@/types/api/CreationImagesData";
import {ViewNames} from "@/router"
import {colorLog} from "@/utils";
import {MetaEvents} from "@/types/Events";
import CreationData, {CreationColumns} from "@/types/api/CreationData";
import {GenerateImageResponse} from "@/types/api/GenerateImageData";
import {RelaxTask, RequestGenerateTaskData} from "@/types/api/GenerateTaskData";
import MetaCopyright from "@/components/MetaCopyright.vue";
import {CommunityCreationData} from "@/types/CommunityCreationData";
import clonedeep from 'lodash.clonedeep';
import _ from 'lodash';
import {Id} from "@/types/api/Common";
import PromptTag from "@/components/PromptTag.vue";
import FadeInsertTransitionGroup from "@/components/transitions/FadeInsertTransitionGroup.vue";
import AdvancedTags from "@/components/AdvancedTags.vue";

@Options({
  name:'Home',
  components: {
    MetaCopyright,
    MetaExampleImage,
    MetaButton,
    LoadingPanel,
    WidthRelatedBox,
    MetaGeneratorSetting,
    MetaMainInput,
    MetaExampleImageGroup,
    MetaProdImageGroup,
    FadeTransition,
    PromptTag,
    FadeInsertTransitionGroup,
    AdvancedTags
  },
  watch:{
    showLoadingPanel(newVal){
      this.loadingProgress = 0;
    }
  },
  computed:{
    generatedCreationColumns(){
      if(!this.prodImages){
        return [];
      }
      const imageSizes = this.prodImages.imageSizes;
      const sizeRatioMap = new Map<string | number,number>();
      let i = 0;
      for(const creation of this.prodImages.creationGroup.creations){
        const [w,h] = imageSizes[i];
        sizeRatioMap.set(creation.id,h / w);
        i++;
      }

      function insert2DArray(columns:CreationData[][],creation:CreationData){
        columns.find((column,index) => {
          const currentHWRatio = sizeRatioMap.get(creation.id)!;
          if(column.length === 0){
            column[0] = creation;
            return true;
          }else if(column.length === 1){
            const [small] = column;
            const smallHWRatio = sizeRatioMap.get(small?.id)!;
            if(smallHWRatio > currentHWRatio){
              column.unshift(creation);
            }else{
              column.push(creation);
            }
            return true;
          }else{
            const [small,large] = column;
            const smallHWRatio = sizeRatioMap.get(small.id)!;
            const largeHWRatio = sizeRatioMap.get(large.id)!;
            if(smallHWRatio > currentHWRatio){
              column[0] = creation;
              insert2DArray(columns.slice(index),small);
              return true;
            }
            if(largeHWRatio <currentHWRatio){
              column[1] = creation;
              insert2DArray(columns.slice(index),large);
              return true;
            }
          }
          return false;
        });
      }

      const grouped:CreationData[][] = this.prodImages.creationGroup.creations.reduce((columns:CreationData[][],creation:CreationData) => {
        insert2DArray(columns,creation);
        return columns;
      },[[],[],[]]);
      const sorted = grouped.sort((a,b) => {
        const [a1,a2] = a;
        const aSum = sizeRatioMap.get(a1.id)! + sizeRatioMap.get(a2.id)!;
        const [b1,b2] = b;
        const bSum = sizeRatioMap.get(b1.id)! + sizeRatioMap.get(b2.id)!
        return aSum - bSum;
      })

      return sorted;
    }
  }
})
export default class Home extends Vue {
  loadingProgress = 0;
  mainInputText = '';
  setting = [];
  exampleImages:CommunityCreationData[] = [];
  prodImages:GenerateImageResponse | null = null;
  abortFnReceiver: AbortReceiver = { abort:undefined }; //接收abort函数
  generatedCreationColumns!:CreationData[][];

  loadingProds = false;
  taskWait = 0;
  loadingProgressBarVisible = false;
  loadingExamples = false;
  showLoadingPanel = false;
  advancedSettingPanelVisible = false;

  $refs!:{
    settingRef:MetaGeneratorSetting,
    exampleImageGroup:MetaExampleImageGroup
  }

  created(){
    store.eventBus.on(MetaEvents.REPEAT_TO_HOME,() => {
      this.abortFnReceiver.abort?.();
      this.mainInputText = '';
      this.prodImages = null;
      this.loadingProgress = 0;
    });
  }

  mounted(){
    colorLog('a router',this.$router)
   this.getMoreExamples();
  }

  deactivated(){
    this.abortFnReceiver.abort?.();
  }


  exemplify(str:string){
    this.mainInputText = str
  }

  detailImage(img:CreationsImagesData){
    store.updateBackViewName(ViewNames.Home);
    const index = store.prodImages.creations.findIndex(pImg => pImg.id === img.id);
    this.$router.push({name:ViewNames.Detail,params:{
        index:index
      }});
  }

  generateCreationColumns(){

  }

  handleAdvancedSettingBtnClicked(){
    this.advancedSettingPanelVisible = !this.advancedSettingPanelVisible;
  }

  activated(){
  }

  async generateImages(){
    if(!store.userInfo){
      this.$router.push({
        name:ViewNames.Login,
        query:{
          redirectViewName:ViewNames.Home
        }
      });
      return;
    }
    if(this.loadingProds) return;
    this.loadingProds = true;
    this.showLoadingPanel = true;
    this.advancedSettingPanelVisible = false;
    try{
      const currentPrompt = this.mainInputText
      let data:RequestGenerateTaskData = {text:  currentPrompt};
      data.properties = this.setting.join(' ');

      let userInfo = store.userInfo;
      if(userInfo.relax){
        await generateRelaxModeImages(data).then(async res=>{
          const relaxTask:RelaxTask = res.data.data;
          if(relaxTask.taskId==null){
            let time = relaxTask.waitTime*1000*60;
            this.loadingProgress=0;
            this.taskWait = time/1000;
            if (!this.taskWait) {
              this.loadingProgressBarVisible = true;
            } else {
              this.loadingProgressBarVisible = false;
            }
            setTimeout(async ()=>{
              this.loadRelaxTask(relaxTask.id);
            },time)
          }
        })
      }else {

        this.prodImages = await getGeneratedImages(data, (taskResult) => {
          this.loadingProgress = taskResult.ratio * 100;
          this.taskWait = Number(taskResult.task_cnt) * 20;
          if (!this.taskWait) {
            this.loadingProgressBarVisible = true;
          } else {
            this.loadingProgressBarVisible = false;
          }
        }, this.abortFnReceiver);
        this.prodImages.creationGroup.creations.forEach((creationData) => {
          creationData.prompt = currentPrompt;
        });

        const group = clonedeep(this.prodImages.creationGroup);
        group.creations = _.unzip(this.generatedCreationColumns).flat(2) as CreationData[];
        store.updateProdImages(group);
        store.updateRecentCreationsNeedNoticeState(true);
        this.showLoadingPanel = false;
        this.loadingProds = false;
      }
    } finally {
      this.showLoadingPanel = false;
      this.loadingProds = false;
      this.loadingProgressBarVisible = false;
    }
  }

  loadRelaxTask(id:Id){
    const currentPrompt = this.mainInputText;
    getRelaxTask(id).then( async res=>{
      if(res.data.code===200){
        const relaxTask = res.data.data;
        if(relaxTask.taskId===null){
          setTimeout(()=>this.loadRelaxTask(id),3000);
        }else{
          this.prodImages = await getGenerateTask(relaxTask.taskId,(taskResult) => {
            this.loadingProgress = taskResult.ratio * 100;
            this.taskWait = Number(taskResult.task_cnt) * 20;
            if (!this.taskWait) {
              this.loadingProgressBarVisible = true;
            } else {
              this.loadingProgressBarVisible = false;
            }
          }, this.abortFnReceiver);
          this.prodImages.creationGroup.creations.forEach((creationData) => {
            creationData.prompt = currentPrompt;
          });

          const group = clonedeep(this.prodImages.creationGroup);
          group.creations = _.unzip(this.generatedCreationColumns).flat(2) as CreationData[];
          store.updateProdImages(group);
          store.updateRecentCreationsNeedNoticeState(true);
          this.showLoadingPanel = false;
          this.loadingProds = false;
        }
      }
    })
  }

  async getMoreExamples(){
    this.advancedSettingPanelVisible = false;
    if(this.loadingExamples) return;

    let proxyRes1:(value?:unknown) => void;
    const pro1 = new Promise(res => proxyRes1 = res);
    const examplePro = getExampleImages();
    const nodes = (this.$refs.exampleImageGroup as MetaExampleImageGroup).exampleImageRefs.map(vn => vn.$el);
    if(nodes.length){
      anime({
        targets:nodes,
        scale:0,
        opacity:0,
        duration:300,
        easing:"linear",
        delay:anime.stagger(200,{grid:[3,2],from:"center"}),
        complete:async () => {
          this.exampleImages = [];
          await this.$nextTick();
          proxyRes1!();
        },
      });
    }else{
      proxyRes1!();
    }
    const [,res] = await Promise.all([pro1,examplePro]);
    this.showLoadingPanel = false;
    this.exampleImages = res.data.data as CommunityCreationData[];
    this.loadingExamples = false;
  }
}
