
import {Options, Vue} from 'vue-class-component';
import MetaButton from "@/components/MetaButton.vue";
import SlideI18nText from "@/components/SlideI18nText.vue";
import anime from "animejs";
import WidthRelatedBox from "@/components/WidthRelatedBox.vue";
import MetaImage from "@/components/MetaImage.vue";
import {PropType} from "vue";
import {CommunityCreationData} from "@/types/CommunityCreationData";
import PromptTag from "@/components/PromptTag.vue";
@Options<MetaExampleImage>({
  components: {
    MetaButton,
    SlideI18nText,
    WidthRelatedBox,
    MetaImage,
    PromptTag
  },
  props: {
    imageData: {
      type:Object as PropType<CommunityCreationData>,
      required:true
    },
    delay:{
      type:Number,
      default:100,
      required:false
    }
  }
})
export default class MetaExampleImage extends Vue {
  imageData!:CommunityCreationData;
  delay!:number;

  onImageLoaded(){
    console.log('image loaded')
  }

  mounted(){
    const selfRef = this.$el;
    const imagePanelRef = this.$refs.imagePanelRef as HTMLElement;
    const preDescPanelRef = this.$refs.preDescPanelRef as HTMLElement;
    const tl = anime.timeline({
      duration:400,
      changeBegin(){
        anime.set(selfRef,{
          opacity:1
        });
      },
      easing:'linear',
      autoplay:false,
    }).add({
      targets:preDescPanelRef,
      opacity:[0,1],
      changeComplete() {
        anime.set(imagePanelRef, {
          opacity: 1,
        });
      },
    }).add({
      easing:'spring(1, 80, 10, 0)',
      targets:[preDescPanelRef,imagePanelRef],
      rotateY:function (el:HTMLElement,i:number){
        if(i == 0){
          return '180deg';
        }else{
          return ['180deg','0deg'];
        }
      },
    },'+=400');

    if(this.delay){
      setTimeout(() => {
        tl.play();
      },this.delay)
    }
  }
}
