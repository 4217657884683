import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c187c12a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "home-top-part" }
const _hoisted_3 = { class: "home-bottom-part" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetaMainInput = _resolveComponent("MetaMainInput")!
  const _component_AdvancedTags = _resolveComponent("AdvancedTags")!
  const _component_SlideI18nText = _resolveComponent("SlideI18nText")!
  const _component_LoadingPanel = _resolveComponent("LoadingPanel")!
  const _component_MetaGeneratorSetting = _resolveComponent("MetaGeneratorSetting")!
  const _component_MetaProdImageGroup = _resolveComponent("MetaProdImageGroup")!
  const _component_MetaExampleImageGroup = _resolveComponent("MetaExampleImageGroup")!
  const _component_FadeTransition = _resolveComponent("FadeTransition")!
  const _component_MetaCopyright = _resolveComponent("MetaCopyright")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MetaMainInput, {
        text: _ctx.mainInputText,
        "onUpdate:text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mainInputText) = $event)),
        onGenerate: _ctx.generateImages,
        loading: _ctx.loadingProds
      }, null, 8, ["text", "onGenerate", "loading"]),
      _createElementVNode("div", {
        class: _normalizeClass(["side-bar", {
      hidden:_ctx.showLoadingPanel
    }])
      }, [
        _createVNode(_component_AdvancedTags, {
          modelValue: _ctx.setting,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.setting) = $event)),
          class: "advanced-setting-tags"
        }, null, 8, ["modelValue"]),
        _createElementVNode("span", {
          class: "advanced-setting-panel-btn side-bar-btn",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleAdvancedSettingBtnClicked && _ctx.handleAdvancedSettingBtnClicked(...args)))
        }, [
          _createVNode(_component_SlideI18nText, { path: "advanced" }),
          _createElementVNode("span", {
            class: _normalizeClass(["advanced-setting-panel-btn-arrow", {
          active:_ctx.advancedSettingPanelVisible
        }])
          }, null, 2)
        ])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FadeTransition, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_ctx.showLoadingPanel)
              ? (_openBlock(), _createBlock(_component_LoadingPanel, {
                  key: 0,
                  wait: _ctx.taskWait,
                  "progress-bar-visible": _ctx.loadingProgressBarVisible,
                  class: "loading-area",
                  progress: _ctx.loadingProgress,
                  "onUpdate:progress": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.loadingProgress) = $event))
                }, null, 8, ["wait", "progress-bar-visible", "progress"]))
              : (_ctx.advancedSettingPanelVisible)
                ? (_openBlock(), _createBlock(_component_MetaGeneratorSetting, {
                    key: 1,
                    ref: "settingRef",
                    modelValue: _ctx.setting,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.setting) = $event))
                  }, null, 8, ["modelValue"]))
                : (_ctx.prodImages)
                  ? (_openBlock(), _createBlock(_component_MetaProdImageGroup, {
                      key: 2,
                      class: "images-area",
                      imageColumns: _ctx.generatedCreationColumns,
                      onDetail: _ctx.detailImage
                    }, null, 8, ["imageColumns", "onDetail"]))
                  : (_openBlock(), _createBlock(_component_MetaExampleImageGroup, {
                      key: 3,
                      exampleImages: _ctx.exampleImages,
                      ref: "exampleImageGroup",
                      class: "images-area",
                      onExemplify: _ctx.exemplify
                    }, null, 8, ["exampleImages", "onExemplify"]))
          ], 1024))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_MetaCopyright)
  ]))
}