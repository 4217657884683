
import {Options, Vue} from 'vue-class-component';
import MetaImage from "@/components/MetaImage.vue";
import WidthRelatedBox from "@/components/WidthRelatedBox.vue";
import {colorLog} from "@/utils";
import {CreationsImagesData} from "@/types/api/CreationImagesData";
import CreationImage from "@/components/CreationImage.vue";
import CreationData, {CreationColumns} from "@/types/api/CreationData";
import {PropType} from "vue";

@Options<MetaProdImageGroup>({
  components: {
    MetaImage,
    WidthRelatedBox,
    CreationImage
  },
  props: {
    imageColumns:{
      required:true,
      type:Object as PropType<CreationColumns>
    }
  },
  computed:{
  }
})
export default class MetaProdImageGroup extends Vue {
  imageColumns!:CreationColumns;

  handleImageClicked(img:CreationData){
    this.$emit('detail',img);
  }
}
